<template>
  <q-card style="width: 600px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        <small class="q-ml-sm text-size-sm">
          编辑小程序医生端用户帐号
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm q-mr-sm">
        <select_hospInfo
          :dense="true"
          outlined
          class="col-12"
          label="选择机构"
          v-model="PdmDrUserinfoEntity.HospID"
          v-model:valueCode="PdmDrUserinfoEntity.HospID"
          @onUpdate="onUpdate"
        />
        <q-input
          label="用户名称"
          dense
          outlined
          class="col-3"
          v-model="PdmDrUserinfoEntity.DR_UserName"
        />
        <q-input
          label="证件号码"
          dense
          outlined
          class="col-4"
          v-model="PdmDrUserinfoEntity.DR_CertNo"
        />
        <q-input
          label="用户手机号"
          dense
          outlined
          class="col-4"
          v-model="PdmDrUserinfoEntity.DR_TelPhone"
        />
        <q-input
          label="标题/职称"
          dense
          outlined
          class="col-4"
          v-model="PdmDrUserinfoEntity.DR_Titles"
        />
      </div>
      <div>
        <q-card class="col-12">
          <div class="row q-my-xs">
            <div class="col-3">
              <div class="text-subtitle1 q-pl-sm">权限选择：</div>
            </div>
           
          </div>
          <q-separator />
          <q-checkbox
          v-model="checkboxList"
          v-for="(item, index) in data"
          :key="index"
          :val="item.TypeID"
          :label="item.TypeName"
        />
        </q-card>
       
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dlg from "@/config/dialog";
import select_hospInfo from "@/views/pdm/comm/hospInfo_select.vue";
import * as MaType0 from "@/api/pdm/ma/maType0";
import * as MaUsertype from "@/api/pdm/ma/maUsertype";

export default {
  components: {
    select_hospInfo,
  },
  props: {},
  computed: {
    ...mapState("PdmDrUserinfo", [
      "PdmDrUserinfoShowDlg",
      "PdmDrUserinfoEntity",
      "PdmDrUserinfoList",
    ]),
  },
  data() {
    return {
      data: [],
      checkboxList: [],
    };
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    ...mapActions("PdmDrUserinfo", [
      "actUpdatePdmDrUserinfo",
      "actGetPdmDrUserinfoList",
    ]),
    onLoadData() {
      MaType0.GetPDM_MA_TypeList({
        HospID: this.PdmDrUserinfoEntity.HospID,
      }).then((res) => {
        if (res.Code == 0) {
          this.data = res.Data;
          this.onGetCheckbox();
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    onOK() {
      this.actUpdatePdmDrUserinfo({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: this.PdmDrUserinfoEntity,
        TypeList:this.checkboxList
      }).then((res) => {
        if (res.Code == 0) {
          this.$emit("onLoadData");
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
    onUpdate() {
      this.onLoadData();
    },
    onGetCheckbox(){
      MaUsertype.GetPDM_MA_UserTypeList({
        HospID: this.PdmDrUserinfoEntity.HospID,
        DR_UserInfoID:this.PdmDrUserinfoEntity.DR_UserInfoID,
      }).then(res=>{
        if (res.Code==0) {
          this.checkboxList=res.OtherData.info;
        } else {
          dlg.alert(res.Message);
        }
      })
    }
  },
};
</script>
