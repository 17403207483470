/*------------------------------------------------
// File Name:PDM_MA_UserType.js
// File Description:PDM_MA_UserType API方法js接口
// Author:zxl
// Create Time:2023/08/07 18:20:50
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _maUsertype: "/PDM_MA_UserType"
}

export default api


//-------------------创建对象-------------------
export function CreatePDM_MA_UserTypeEntity(parameter) {
    return axios({
        url: api._maUsertype + "/CreatePDM_MA_UserTypeEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取列表-------------------
export function GetPDM_MA_UserTypeList(parameter) {
    return axios({
        url: api._maUsertype + "/GetPDM_MA_UserTypeList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取列表---------------
export function GetPDM_MA_UserTypeListByPage(parameter) {
    return axios({
        url: api._maUsertype + "/GetPDM_MA_UserTypeListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取对象---------------
export function GetPDM_MA_UserTypeEntity(parameter) {
    return axios({
        url: api._maUsertype + "/GetPDM_MA_UserTypeEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存对象---------------
export function UpdatePDM_MA_UserTypeEntity(parameter) {
    return axios({
        url: api._maUsertype+ "/UpdatePDM_MA_UserTypeEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉对象---------------
export function DeletePDM_MA_UserTypeEntity(parameter) {
    return axios({
        url: api._maUsertype + "/DeletePDM_MA_UserTypeEntity",
        method: 'post',
        data: parameter
    })
}
