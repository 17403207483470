/*------------------------------------------------
// File Name:PDM_MA_Type.js
// File Description:PDM_MA_Type API方法js接口
// Author:zxl
// Create Time:2023/08/07 16:47:33
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _maType: "/PDM_MA_Type"
}

export default api


//-------------------创建预约分类对象-------------------
export function CreatePDM_MA_TypeEntity(parameter) {
    return axios({
        url: api._maType + "/CreatePDM_MA_TypeEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取预约分类列表-------------------
export function GetPDM_MA_TypeList(parameter) {
    return axios({
        url: api._maType + "/GetPDM_MA_TypeList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取预约分类列表---------------
export function GetPDM_MA_TypeListByPage(parameter) {
    return axios({
        url: api._maType + "/GetPDM_MA_TypeListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取预约分类对象---------------
export function GetPDM_MA_TypeEntity(parameter) {
    return axios({
        url: api._maType + "/GetPDM_MA_TypeEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存预约分类对象---------------
export function UpdatePDM_MA_TypeEntity(parameter) {
    return axios({
        url: api._maType+ "/UpdatePDM_MA_TypeEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉预约分类对象---------------
export function DeletePDM_MA_TypeEntity(parameter) {
    return axios({
        url: api._maType + "/DeletePDM_MA_TypeEntity",
        method: 'post',
        data: parameter
    })
}
